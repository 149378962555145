export function isEmailValid(email){
    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    email = email.trim()
    if(!email.match(mailformat)){
        return false
    }
    return true
}

export function isPhoneValid(number){
    const num = /^\d+$/;
    number = number.trim()
    if(!(number.length > 6 && number.length < 11) || !num.test(number)){
        return false
    }
    return true
}

export function isNumber(number){
    const num = /^\d+$/;
    number = number
    if(!num.test(number)){
        return false
    }
    return true
}

export function validURL(str) {
    const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str))
    {
        return true;
    }
    else
    {
        return false;
    }
}




export function isNameValid(name){
    const nameformat = /^[a-zA-Z\s]*$/;
    name = name.trim()
    if(!name.match(nameformat) || name.length < 2){
        return false
    }
    return true
}

export function isDescriptionValid(content){
    content = content.trim()
    const words = content.split(" ")
    return words.length > 19 ? true : false
}