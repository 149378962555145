import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			links: [
				// {
				//     icon: 'ri-building-4-line ',
				//     name: 'Staff',
				//     url: '/staff',
				//     isSubmenuPresent: true,
				//     submenus: [
				//         {
				//             name: 'Manager',
				//             url: '/staff/manager'
				//         },
				//         {
				//             name: 'Supervisor',
				//             url: '/staff/supervisor'
				//         },
				//         {
				//             name: 'Cleaner',
				//             url: '/staff/cleaner'
				//         }
				//     ],
				// },
				{
					icon: ' ri-wallet-line ',
					name: 'Customer',
					url: '/customer',
					isSubmenuPresent: true,
					submenus: [
						{
							icon: ' ri-wallet-line ',
							name: 'Bookings',
							url: '/customer/bookings',
							isSubmenuPresent: false,
						},
						{
							name: 'Payments',
							url: '/customer/payments',
						},
					],
				},
				{
					icon: ' ri-wallet-line ',
					name: 'Packages',
					url: '/servicepackages',
					isSubmenuPresent: true,
					submenus: [
						{
							name: 'ServicePackages',
							url: '/servicepackages',
						},
						{
							name: 'AddServicePackage',
							url: '/servicepackageadd',
						},
					],
				},
				{
					icon: ' ri-wallet-line ',
					name: 'Vehicles',
					url: '#',
					isSubmenuPresent: true,
					submenus: [
						{
							name: 'Cars',
							url: '/vehicles/cars',
						},
						{
							name: 'Bikes',
							url: '/vehicles/bikes',
						},
					],
				},
				{
					icon: 'ri-find-replace-fill',
					name: 'Places',
					url: '/places',
					isSubmenuPresent: true,
					submenus: [
						{
							name: 'Locations',
							url: '/places/locations',
						},
						{
							name: 'Communities',
							url: '/places/apartments',
						},
						{
							name: 'Blocks',
							url: '/places/blocks',
						},
					],
				},
				{
					icon: 'ri-dashboard-line',
					name: 'Profile',
					url: '/profile',
					isSubmenuPresent: false,
				},
			],
		};
	}


	render() {
		return (
		
			<div className="vertical-menu" id="mobile_sidebar" style={{ width:this.props.iconShow? "240px":"70px"}}>
			<div data-simplebar className="h-100">
			  {/*- Sidemenu */}
			  <div className="simplebar-offset" style={{right: '-17px', bottom: '0px'}}>
				  <div className="simplebar-content-wrapper">
					  <div className="simplebar-content" style={{padding: '0px'}}>
			  <div id="sidebar-menu">
			
				{/* Left Menu Start */}
				<ul className="metismenu list-unstyled" id="side-menu">
					<li>
					<Link to="/" className=" waves-effect">
					<i className="ri-dashboard-line" aria-hidden="true" style={{padding:"10px",fontSize:"1.4rem"}} />
					{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Home</span> : null }
					</Link>
				  </li>
				  <li>
					<Link to="/users"  className="waves-effect">
					  <i className="ri-calendar-2-line" aria-hidden="true" style={{padding:"10px",fontSize:"1.4rem"}} />
					
					  { this.props.iconShow ? <span style={{display: "inline-block",width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>User</span> : null }
					</Link>
				  </li>
				  <li>
					<Link to="/students" className=" waves-effect">
					<i className="ri-chat-1-line" aria-hidden="true" style={{padding:"10px",fontSize:"1.4rem"}} />
					{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Student</span> : null }
					</Link>
				  </li>
				  <li>
					<Link to="/parents" className=" waves-effect">
					<i className="ri-store-2-line" aria-hidden="true" style={{padding:"10px",fontSize:"1.4rem"}} />
					{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Parent</span> : null }
					</Link>
				  </li>
				  <li>
					<Link to="/experts" className="has-arrow waves-effect">
					<i className="ri-mail-send-line" aria-hidden="true" style={{padding:"10px",fontSize:"1.4rem"}} />
					{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Expert</span> : null }
					</Link>
					
				  </li>
				  {/* <li>
					<Link to="/hobbies" className="has-arrow waves-effect">
					<i className="ri-artboard-2-line" aria-hidden="true" style={{padding:"10px",fontSize:"1.4rem"}} />
					{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Hobby</span> : null }
					</Link>
			   
				  </li> */}
				  <li>
					<Link to="/topics" className="has-arrow waves-effect">
					<i className="ri-layout-3-line" aria-hidden="true" style={{padding:"10px",fontSize:"1.4rem"}} />
					{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Topic</span> : null }
					</Link>
				   
				  </li>
				  <li>
					<Link to="/story" className="has-arrow waves-effect">
					<i className="ri-account-circle-line" aria-hidden="true" style={{padding:"10px",fontSize:"1.4rem"}} />
					{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Story Admin</span> : null }
					</Link>
				   
				  </li>
				  <li>
					<Link to="/organisations" className="has-arrow waves-effect">
					<i className="fa fa-address-book-o" aria-hidden="true" style={{padding:"10px",fontSize:"20px"}} />
					{ this.props.iconShow ? <span style={{width:"100px"}}>Organisation</span> : null }
					</Link>
				   
				  </li>
				  <li>
					<Link to="/workshop" className="has-arrow waves-effect">
					<i className="fa fa-address-book-o" aria-hidden="true" style={{padding:"10px",fontSize:"20px"}} />
					{ this.props.iconShow ? <span style={{width:"100px"}}>Workshop</span> : null }
					</Link>
				   
				  </li>
				  <li>
					<Link to="/workshopBookings" className="has-arrow waves-effect">
					<i className="fa fa-address-book-o" aria-hidden="true" style={{padding:"10px",fontSize:"20px"}} />
					{ this.props.iconShow ? <span style={{width:"100px"}}>Workshop Bookings</span> : null }
					</Link>
					</li>
					<li>
					<Link to="/videos" className="has-arrow waves-effect">
					<i className="fa fa-address-book-o" aria-hidden="true" style={{padding:"10px",fontSize:"20px"}} />
					{ this.props.iconShow ? <span style={{width:"100px"}}>Video</span> : null }
					</Link>
				   
				  </li>

				  <li>
					<Link to="/workshopsessions" className="has-arrow waves-effect">
					<i className="fa fa-address-book-o" aria-hidden="true" style={{padding:"10px",fontSize:"20px"}} />
					{ this.props.iconShow ? <span style={{width:"100px"}}>workshopSession</span> : null }
					</Link>
				   
				  </li>
				  <li>
					<Link to="/dailychallenges" className="has-arrow waves-effect">
					<i className="fa fa-address-book-o" aria-hidden="true" style={{padding:"10px",fontSize:"20px"}} />
					{ this.props.iconShow ? <span style={{width:"100px"}}>Dailychallenges</span> : null }
					</Link>
				   
				  </li>

				  <li>
					<Link to="/psychometrictests" className="has-arrow waves-effect">
					<i className="fa fa-address-book-o" aria-hidden="true" style={{padding:"10px",fontSize:"20px"}} />
					{ this.props.iconShow ? <span style={{width:"100px"}}>Psychometric Tests</span> : null }
					</Link>
				   
				  </li>
				  
				</ul>
			  </div>
			  {/* Sidebar */}
			</div>
			</div>
			</div>
			</div>
		  </div>
		);
	}
}

export default Sidebar;