import React, {Component} from 'react';
import {Button, Form, FormControl} from 'react-bootstrap';
import {Redirect, withRouter, Link} from 'react-router-dom';
import { isEmailValid } from '../../../util/validation';
import './login.css'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordVisible: false,
            username:'',
            userpass:'',
            nameerror: '',
            passerror: ''
        };
    }

    handleChangeUsername = (e)=> {
        this.setState({username: e.target.value});
      }
      handleChangeUserpass = (e) => {
        this.setState({userpass: e.target.value});
      }

    submitForm = (e) => {
        e.preventDefault();
        const { username, userpass } = this.state
        let errors = {}
        if(username.length < 1){
            errors["username"] = "Username is required"
        }else if(!isEmailValid(username)){
            errors["username"] = "Username should be a valid email"
        }
        if(userpass.length < 1){
            errors["userpass"] = "Password is required"
        }
        if(errors["username"] || errors["userpass"]){
            this.setState({
                nameerror: errors["username"],
                passerror: errors["userpass"]
            })
            return;
        }
        let creds = {
            username: this.state.username,
            password: this.state.userpass
        };
        this.props.loginUser(creds);
    };

    togglePasswordVisibility = () => {
        this.setState({
            isPasswordVisible: !this.state.isPasswordVisible
        });
    };


    render() {
        if (this.props.isAuthenticated) {
            return <Redirect
                to={this.props.location && this.props.location.state && this.props.location.state._refurl ? this.props.location.state._refurl : '/'}/>
        } else {
            return (
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <div className="col-lg-4">
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-9">
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                {/*<a href="index.html" className="logo"><img src="assets/images/logo-dark.png" height={20} alt="logo" /></a>*/}
                                            </div>
                                            {/* <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                            <p className="text-muted">Sign in to continue to Honc.</p> */}
                                                    </div>
                                        <div className="p-2 mt-5">
                                        <Form onSubmit={(e) =>
                            this.submitForm(e)} className="form-horizontal"
                            id="validate-form">
                            <div className="form-group mb-md">
                                <div className="col-xs-12">
                                    <div className="input-group" id="login-email">
                                       
                                        <i className="ri-user-2-line auti-custom-input-icon"/>
                                        
                                        <FormControl
                                        ref={ref =>
                                        {
                                        this.username = ref;
                                        }}
                                        onChange={this.handleChangeUsername}
                                        type="text" name="email" value={this.state.username}
                                        placeholder="Username" className="input_login"/>
                                        
                                    </div>
                                    {this.state.nameerror ? <small style={{color: "red"}}>*{this.state.nameerror}</small> : null}
                                </div>
                            </div>
                            <div className="form-group mb-md">
                                <div className="col-xs-12">
                                    <div className="input-group" id="login-password">
                                       
                                        <i className="ri-lock-2-line auti-custom-input-icon"/>
                                       
                                        <FormControl
                                        ref={ref =>
                                        {
                                        this.userpass = ref;
                                        }}
                                        onChange={this.handleChangeUserpass}
                                        type={this.state.isPasswordVisible ? 'text' : 'password'} name="password" value={this.state.userpass}
                                        id="exampleInputPassword1" placeholder="Password" className="input_login"/>
                                        <span className="input-group-addon" onClick={() => this.togglePasswordVisibility()}>
                                        <i className={this.state.isPasswordVisible ? "ti ti-lock" : 'ti ti-eye'}/>
                                        </span>
                                        
                                    </div>
                                    {this.state.passerror ? <small style={{color: "red"}}>*{this.state.passerror}</small> : null}
                                </div>
                            </div>
                            <div className="custom-control custom-checkbox">
                                            <div>
                                                <div>
                                                    <input type="checkbox" className="custom-control-input"
                                                           id="customControlInline"/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customControlInline">Remember me</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-n">
                                            <div className="col-xs-12  " >
                                                <Button style={{margin:"10px"}}
                                                    disabled={this.props.isLoggingIn}
                                                    onClick={(e) => this.submitForm(e)}
                                                    className="btn btn-primary pull-right " type="submit"
                                                    id="login-btn">Login
                                                </Button>
                                                {/* <Button 
                                                onClick= {() =>  this.props.history.push("/signup")}
                                                id="signup-btn" style={{marginTop:"10px"}}>SignUp
                                                </Button> */}
                                            </div>
                                            {/* <span style={{marginTop:"14px",display:"block"}}> Forgot Password <Link to="/forgotpassword"> here</Link></span> */}
                                        </div>
                                        {/* <span>{{}}</span> */}
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
                <div className="col-lg-8">
                    <div className="authentication-bg" style={{width: "100%",height: "100vh"}}>
                       <img src="https://cdn.zillskill.com/2020/12/30140722/Untitled-design-1-1-1024x576.jpg" style={{width:"100%",objectFit:"cover",height:"100vh"}}/>
                    </div>
                </div>
        
                

            </div>
            </div>
            );
        }
    }
}


export default withRouter(Login);

