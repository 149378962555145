import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import _  from 'lodash';
import callApi from './util/apiCaller';
import UserListPage from './modules/Admin/UserList/UserListPage';
import Sidebar from "./modules/App/Sidebar/Sidebar"
import LoginPage from './modules/App/LoginPage/Login'
import Footer from './modules/App/Footer/Footer'
import HomePage from './modules/App/HomePage/HomePage'
import ForgotPassword from './modules/App/ForgetAndReset/ForgotPassword'
import ResetPassword from './modules/App/ForgetAndReset/ResetPassword'
import SubHeader from "./modules/App/Header/SubHeader"
import ProfilePage from './modules/App/ProfilePage/ProfilePage';

const PrivateRoute = ({ Component: component, isAuthenticated: isAuthenticated, ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuthenticated
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: { _refurl: props.location },
            }} />
    )} />
);

class App extends Component {

    constructor() {
        super();
        this.state = {
            isLoggingIn: false,
            isAuthenticated: false,
            showSidebar:true,
            sidebarVisible: true
        }
        this.logoutUser = this.logoutUser.bind(this)
    }

    componentDidMount() {
        if(window.localStorage.getItem("user")){
            this.setState({ isAuthenticated: true })
        }
        console.log(window.screen.width)
        let token = window.localStorage.getItem('token');
        if (token && token !== '') {
            this.setState({
                isLoading: true
            });
            this.verifyToken(token, (err, user) => {
                if (err) {
                    window.localStorage.removeItem('token');
                } else {
                    this.isAuthenticated = true;
                    let model = {
                        user: user
                    };
                    if (!_.isEmpty(model.user)) {
                        this.setState({
                            isMounted: true,
                            isLoading: false,
                            isAuthenticated: true,
                            userid: model.user._id,
                            token: token,
                            user: model.user,
                        });
                    }
                }
            });
        }
    }


    loginUser = (creds) => {
        this.setState((prevState) => {
            return {
             ...prevState,
             isLoggingIn : !prevState.isLoggingIn
           }
       })
        callApi(`auth/login`, 'post', creds).then(res => {
            console.log(creds)
            if (res && res.user) {
                if(res.user.token){
                    //saving the user in local storage
                    window.localStorage.setItem('user', JSON.stringify(res.user));
                    this.setState((prevState) => {
                        return {
                            ...prevState,
                            isLoggingIn : !prevState.isLoggingIn,
                            isAuthenticated: !prevState.isAuthenticated
                          }
                   })

                } else {
                    this.setState({
                        isLoggingIn : false
                    })
                    this.showMessage("User is not present", 'error');
                }
            }
        })
        .catch(error =>{
            console.log(error);
        });
    };

    verifyToken = (token, next) => {
        callApi(`auth/verify`, 'post', {
            token: token
        }).then(res => {
            if (res.user) {
                let user = res.user;
                return next(null, user);
            } else {
                return next('Unauthorized');
            }
        });
    };

    logoutUser() {
        window.localStorage.removeItem("user")
        this.setState({ isAuthenticated: false })
    };

    showMessage = (msg, msgtype) => {
        alert(msg);
    };

    togglesidebar(){
        this.setState((prevState) => {
            return {
             ...prevState,
             showSidebar: !prevState.showSidebar
           }
       })
    }

    render() {
        return (
            <div id="layout-wrapper">
                <Router>
                    {this.state.isAuthenticated ?
                        <SubHeader logoutUser={this.logoutUser} handletoggle={() => this.togglesidebar()} headerShow={this.state.showSidebar}/> : null
                    }
                    {this.state.isAuthenticated ?
                        <Sidebar iconShow={this.state.showSidebar}/> : null
                    }


                    <div className="main-content">
                        <div className="page-content">
                            <div>
                                <Switch>
                                    <Route
                                        exact path="/"
                                        render={props => <HomePage
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                            showSidebar={this.state.showSidebar}
                                        />}
                                    />
                                    <Route
                                        exact path="/profile"
                                        render={props => <ProfilePage
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            showSidebar={this.state.showSidebar}
                                        />}
                                    />
                                    <Route
                                        exact path="/users"
                                        render={props => <UserListPage
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            showSidebar={this.state.showSidebar}
                                        />}
                                    />
                                  
                                    <Route
                                        exact path="/login"
                                        render={props => <LoginPage
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                     {/* <Route
                                        exact path="/signup"
                                        render={props => <SignupPage
                                            {...props}

                                        />}
                                    /> */}
                                    <Route
                                        exact path="/forgotpassword"
                                        render={props => <ForgotPassword
                                            {...props}

                                        />}
                                    />
                                     <Route
                                        exact path="/reset/:token"
                                        render={props => <ResetPassword
                                            {...props}

                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/home"
                                        component={props => <HomePage
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>
                    {this.state.isAuthenticated ?
                        <Footer footerShow={this.state.showSidebar} /> :
                        null
                    }
                </Router>
            </div>
        );
    }
}

export default App;